<template>
  <div>
     <button @click="modalShow = !modalShow"  class="btn m-0 p-0 d-flex align-items-center justify-content-center pageOptionModalBtn"><BIconThreeDots
                      class="font-weight-bold p-0 m-0"
                      scale="0.75"
                      style="color: #fff;"
                    /></button>

  <b-modal v-model="modalShow" size="md" :id="ModalId"  hide-header hide-footer centered>
    <div class="p-3">
        <div>
            <div
                    class="QuizNameInput  d-flex align-items-center w-100 pr-3 py-2"
                  >
                    <input
                      type="text"
                      class="mx-2 flex-grow-1"
                     
                      v-model="block.name"
                    />
                  
                  </div>

                  <div class="d-flex justify-content-between pt-4">
                    <button v-if="hideDuplicatedBtn"   class="btn functionBtnStyles" :disabled="duplicateSpinner " @click="$emit('duplicatePage',{id:block.id,index:pageIndex})"> 
                    
                     <span v-if="!duplicateSpinner"><i class="fa fa-clone pr-3" aria-hidden="true"></i>  Duplicate</span>
                          <div
                            v-else
                            class="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                    </button>
                    <button class="btn functionBtnStyles" :disabled="block.value == 'resultPage'"  @click="initateDeletePage() ">
                    
                     <span v-if="!deleteSpinner"><i class="fa fa-trash" aria-hidden="true"></i> Delete</span>
                          <div
                            v-else
                            class="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                    </button>
                  </div>
        </div>
    </div>
  </b-modal>
  </div>
</template>

<script>
import {  BIconThreeDots } from "bootstrap-vue";
export default {
  components:{
    BIconThreeDots
  },
  props:['block','index','duplicateSpinner','pageIndex','deleteSpinner','isCloseModal'],
    data(){
        return {
            modalShow: false,
            QuizName:''
        }
    },
    methods:{
      initateDeletePage(){
           this.$emit('initateDeletePage', {'page': this.block,"index": this.pageIndex}) 
    
      },
      closeModal(){
        this.modalShow = false
      }
       
    },
    computed:{
       ModalId() {
      return this.index.toString()
    },
    hideDuplicatedBtn(){
      let allowedPages = ['getStartedPage','singleAnswer','multipleAnswer','leadPage']

      return allowedPages.includes(this.block.value)
    }
    },
    watch:{
      duplicateSpinner(newVal,oldVal){
         if (oldVal === true && newVal === false) {
        this.modalShow = false
      }
      },

      isCloseModal(newValue){
        if(newValue){
          this.closeModal()
        }
      }
    },

}
</script>

<style scoped>

.pageOptionModalBtn{
  background:#7C8290;height:15px;
}
.pageOptionModalBtn:hover{
  background:#4d1b7e;
}
.QuizNameInput {
  border: 0.8px solid #b3afb6;
  box-sizing: border-box;
  border-radius: 6px;
}

.QuizNameInput input {
  background: transparent;
  border: none;
  outline: none;
}
.QuizNameInput input:focus {
  border: none;
  outline: none;
}

.functionBtnStyles{
  /* background: red; */
color:#000;
  outline: 1px solid #b3afb6;
}
</style>